import React, { useEffect, useState } from 'react';
import axios from 'axios';

function App() {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Corrected axios.get method with headers
    axios.get('/feed/goodreputationrealestatebroker/privatesite/66f0be0c99d636f2f2d08a114c160dd4', {
      headers: {
        'Content-Type': 'application/xml; charset=utf-8',
      }
    })
    .then(response => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(response.data, 'text/xml');
      const items = Array.from(xml.getElementsByTagName('property'));

      // Extract data from XML
      const properties = items.map(item => ({
        id: item.getElementsByTagName('id')[0].textContent,
        title: item.getElementsByTagName('title')[0].textContent,
        description: item.getElementsByTagName('description')[0].textContent,
        price: item.getElementsByTagName('price')[0].textContent,
        // Add more fields as needed
      }));

      setProperties(properties);
    })
    .catch(error => {
      console.error('Error fetching the XML feed:', error);
    });
  }, []);

  return (
    <div className="App p-4">
      <h1 className="text-2xl font-bold mb-4">Property Listings</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {properties.map(property => (
          <div key={property.id} className="bg-white shadow-md rounded p-4">
            <h2 className="text-xl font-semibold">{property.title}</h2>
            <p>{property.description}</p>
            <p className="text-green-500 font-bold mt-2">${property.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
